.login-page-image-sec {
  background-color: #1B2132;
  padding: 30px;
  height: 100%; 

  .title-container {
    p {
     font-size: 25px;
      color: white;
      margin: 0;
    }
  }
}

.img-container {
  display: flex;
  flex-direction: column;
  padding: 50px 5px 5px 35px;
  flex-grow: 1;
  //justify-content: space-around;

  h3:nth-child(1) {
    color: #fff;
    font-size: 50px;
    margin: 0;

  }

  h3:nth-child(2) {
    font-size: 45px;
    margin: 10px 0 0 0;
    color: white;
    line-height: 50px;
  }

  img {
    width: 95%;
    height: 50%;
    margin-top: 30px;
  }
}

.logoImage{
  width: 10% !important;
  height: auto !important;
}
.hoverIt:hover{
  cursor: pointer !important;
}

.main-wrapper{
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-overlay {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: linear-gradient(90.19deg, rgba(25, 54, 95, 0.7) 0.18%, rgba(0, 0, 0, 0) 99.86%);
  content: '';
}
.login-wrapper {
  position: relative;
  z-index: 2;
}
.login-container{
  display: flex;
  flex-direction: column;
  height: 100%;
}
.login-left {
  margin-left: auto;
}
.login-right {
  align-self: center;
}

.login-title-h {
  font-weight: 700 !important;
  font-size: 54px !important;
  line-height: 70px !important;
  color: #FFFFFF !important;
  margin-top: 20px !important;
}

.login-title-h span {
  color: #fff !important;
  -webkit-text-stroke: 2px !important;
  -webkit-text-fill-color: transparent !important;
}

.login-form {
  background-color: #FFFFFF;
  border-radius: 15px;
  box-shadow: 0px 8px 8px RGB(0 0 0 / 10%);
  padding: 80px 58px;
  box-sizing: border-box;
}

.login-form h2{
  font-weight: 700;
  font-size: 27.9537px;
  line-height: 42px;
  margin-top: 0px;
  margin-bottom: 5px;
  color: #252D40;
  text-align: center;
}

.login-form p{
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 20px;
  color: #667488;
  opacity: 0.6;
}

.login-form .text-field-email {
  position: relative;
  margin-bottom: 15px;
}

.login-form .text-field-password {
  position: relative;
  margin-bottom: 10px;
}

.login-form .text-field-email input, 
.login-form .text-field-password input {
  border: 1px solid #252D40 !important;
  font-weight: 400;
  font-size: 13px;
  color: #252D40;
}

.login-form .text-field-email input:focus, 
.login-form .text-field-password input:focus {
  border: 1px solid rgba(14, 151, 255, 0.3) !important;
  background: #EFF7FD !important;
}

.text-field-email fieldset, .text-field-password fieldset {
  border: none!important;
  outline: none!important;
}

.left-icon {
  position: absolute;
  left: 27px;
  top: 60%;
  transform: translate(0px, -50%);
}

.right-icon{
  position: absolute;
  right: 27px;
  top: 60%;
  transform: translate(0px, -50%);
}

.login-button {
  height: 55.21px !important;
  margin-top: 23px !important;
  background: #0E97FF !important;
  color: #FFFFFF !important;
  border-radius: 6.98842px !important;
}

.background{
  background-image: url('../../../assets/images/CMS-login-bg.jpg') !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.login-form .MuiOutlinedInput-root {
  border-radius: 6px !important;
}

.login-form .MuiInputBase-input {
  height: 35.21px !important;
  padding: 10px 60px !important;
  border-radius: 6px !important;
}

.radio-label{
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #252D40;
  margin-bottom: 0px !important;
}

@media (max-width: 1199px) {
  .login-title-h {
    font-size: 44px !important;
    line-height: 56px !important;
    margin-top: 20px !important;
    margin-bottom: 30px !important;
  }
}

@media (max-width: 959px) {
  .background{
    background-image: url('../../../assets/images/CMS-Mobile-login-bg.jpg') !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }
}

@media (max-width: 767px) {
  .login-title-h {
    font-size: 25px !important;
    line-height: 35px !important;
    margin-top: 10px !important;
    margin-bottom: 0px !important;
  }
  .login-title-h span {
    -webkit-text-stroke: 1px !important;
  }
  .logo{
    width: 60px;
  }
  .login-form {
    padding: 30px 25px;
  }
  .login-form h2 {
    font-size: 20.9537px;
    line-height: 34px;
  }
  .MuiInputBase-input {
    height: 26.21px !important;
  }
  .login-form p {
    margin-bottom: 8px;
  }
  .login-button {
    height: 46.21px !important;
    margin-top: 9px !important;
  }
}

@media (max-width: 350px) {
  .login-title-h {
    font-size: 20px !important;
    line-height: 28px !important;
    margin-top: 5px !important;
    margin-bottom: 0px !important;
  }
}

@media (min-width: 1600px) {
  .login-wrapper-container{
    max-width: 1420px !important;
  } 
}